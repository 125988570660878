<template>
  <div>
    <v-card class="pa-0 tab-item-card" flat>
      <v-img class="section-title-bg-img" src="../../assets/deposit.jpg"></v-img>
      <div class="text-center py-12">
        <span class="text-h4 text-sm-h2 text-md-h1">Deposit prescribed information</span>
      </div>
      <v-container class="px-5 py-8 py-sm-4">
        <p>Section A.	Prescribed Information for Assured Shorthold Tenancie</p>
        <p>Under the Housing Act 2004, the landlord is required to give the following information to the tenant and anyone who paid the deposit on the tenant's behalf (a Relevant Person) within 30 days of receiving the deposit.   This is to ensure that tenants are made aware of their rights during and at the end of the tenancy regarding the deposit.</p>
        <p>(a) The scheme administrator of the Tenancy Deposit Scheme is:</p>
        <div>
          <p>The Dispute Service Limited</p>
          <p>West Wing, First Floor</p>
          <p>Maylands Building</p>
          <p>200 Maylands Avenue</p>
          <p>Hemel Hempstead</p>
          <p>Herts HP2 7TG</p>
          <div>
            <p>Phone	0300 037 1000</p>
            <p>Email	<a href="#">deposits@tenancydepositscheme.com</a></p>
            <p>Web	<a href="#">www.tenancydepositscheme.com</a></p>
          </div>
        </div>
        <p>(b) A leaflet entitled What is the Tenancy Deposit Scheme?, which explains the operation of the provisions contained in sections 212 to 215 of, and Schedule 10 to, Housing Act 2004, must accompany this document when given to the tenant and any relevant person.</p>
        <p>(c) The procedures that apply under the scheme by which an amount in respect of a deposit may be paid or repaid to the tenant at the end of the tenancy are set out in the scheme leaflet: What is the Tenancy Deposit Scheme?, which accompanies this document.</p>
        <p>(d) The procedures that apply under the scheme where either the landlord or the tenant is not contactable at the end of the tenancy are set out in the Scheme Leaflet: What is the Tenancy Deposit Scheme?</p>
        <p>(e) The procedures that apply where the landlord and the tenant dispute the amount of the deposit to be paid or repaid are summarised in the Scheme Leaflet What is the Tenancy Deposit Scheme? More detailed information is available on: www.tenancydepositscheme.com.</p>
        <p>(f) The facilities available under the scheme for enabling a dispute relating to the deposit to be resolved without recourse to litigation are set out in the Scheme Leaflet: What is the Tenancy Deposit Scheme? More detailed information is available on: www.tenancydepositscheme.com."</p>
        <p></p>
        <p></p>
      </v-container>
      <div class="primary darken-4 text-center py-12">
        <v-container class="px-5 py-8">
          <p class="white--text text-h5 text-sm-h4">Your landlord or agent must register the deposit and give you the required information within 30 calendar days of payment</p>
        </v-container>
      </div>
    </v-card>
    <MainPagination 
      previousPage="/review/deposit"
      nextPage="/review/conditions"
    />
  </div>
</template>

<script>
export default {
  name: 'agreement-deposit-info'
}
</script>

<style>

</style>